<template>
  <div class="mobileAir">
     <mHead :name="'空调面板'" :add="'/mobile/ProAir'" :adds="'/mobile/ProAirParam'" :color="true" />
    <div class="banner">
      <div class="title">随心控制 舒适智能</div>
      <div class="text">带给你不同于</div>
      <div class="text texts">传统智能家居的新体验</div>
      <div class="ul">
        <div class="li">隔绝干扰</div>
        <div class="li">远程控制</div>
        <div class="li">语音控制</div>
      </div>
    </div>
    <div class="sec1">
      <div class="title">随时随地享受科技带来的便捷</div>
      <div class="secBox">
        <img src="../../assets/mobile/product/air1.png" class="secImg" />
        <div class="tit">专有技术 稳定抗干扰</div>
        <div class="text">产品采用专用频段，有效隔绝干扰</div>
      </div>
      <div class="secBox">
        <img src="../../assets/mobile/product/air2.png" class="secImg" />
        <div class="tit">智能音箱 语音控制</div>
        <div class="text">接入智能音箱，只需说句话就能轻松控制空调</div>
      </div>
      <div class="secBox">
        <img src="../../assets/mobile/product/air3.png" class="secImg" />
        <div class="tit">手机小程序 远程控制</div>
        <div class="text">打开微信小程序，随时随地控制空调</div>
      </div>
    </div>
    <div class="sec2">
      <div class="title">贴心设计，让科技更懂你</div>
      <div class="secBox">
        <img src="../../assets/mobile/product/air4.png" class="secImg" />
        <div class="tit">操作简单，无需思考</div>
        <div class="text">简单而符合直觉的界面设计，在提升效率的同时又不失掌控</div>
      </div>
       <div class="secBox">
        <img src="../../assets/mobile/product/air5.png" class="secImg" />
        <div class="tit">手机操控，方便快捷</div>
        <div class="text">拿起手机一键开关，简单又快捷</div>
      </div>
       <div class="secBox">
        <img src="../../assets/mobile/product/air6.png" class="secImg" />
        <div class="tit">实时监控，安全省心</div>
        <div class="text">出门在外，轻松获取室内温度，提前打开空调，开门即享清凉</div>
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  }
};
</script>
<style scoped lang="less">
.banner {
  width: 100%;
  height: 8rem;
  background-image: url(../../assets/mobile/product/airBanner.png);
  background-size: 100% 100%;
  color: #333;
  padding-left: 0.3rem;
  text-align: left;
  .title {
    padding-top: 5.05rem;
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
  }
  .text {
    margin-top: 0.23rem;
    font-size: 0.3rem;
    line-height: 0.3rem;
    font-weight: 400;
    margin-top: 0.24rem;
    font-size: 0.24rem;
    line-height: 0.24rem;
  }
  .texts {
    margin-top: 0.14rem;
  }
  .ul {
    margin-top: 0.48rem;
    color: #ff9000;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: bold;
    display: flex;
    .li {
      padding-left: 0.2rem;
      padding-right: 0.19rem;
      border-right: 0.02rem solid #333;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
        border-right: 0;
      }
    }
  }
}
.sec1 {
  background-color: #f8f8f8;
  padding: 0 0.3rem;
  padding-top: 0.8rem;
  padding-bottom: 0.3rem;
  color: #333333;
  text-align: center;
  .title {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  .secBox {
    width: 100%;
    background-color: #fff;
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    .secImg {
      width: 100%;
    }
    .tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      margin-top: 0.28rem;
    }
    .text {
      margin-top: 0.29rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
    }
  }
}
.sec2 {
  padding: 0.8rem 0;
  background-color: #fff;
   color: #333333;
  text-align: center;
  .title {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  .secBox {
    width: 100%;
    padding: 0 0.3rem;
    background-color: #fff;
    margin-bottom: 0.3rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    &:last-of-type {
      margin-bottom: 0;
    }
    .secImg {
      width: 100%;
    }
    .tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      margin-top: 0.3rem;
    }
    .text {
      margin-top: 0.19rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
    }
  }
}

</style>